<template>
  <div>
    <v-container>
      <v-row style="margin-top: 1%;">
        <v-col
            cols="12"
            md="6"
        >
          <v-select
              v-model="flux.supply_id"
              dense
              :items="supplies"
              :item-value="'id'"
              :item-text="item=>item.sup_name"
              label="Fourniture concerner"
              outlined
          ></v-select>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              v-model="flux.flux_create_date"
              dense
              type="date"
              required
              hide-details
              label="Date de depot"
              outlined
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              v-model="flux.flux_amount"
              dense
              type="numeric"
              hide-details
              required
              label="Quantité"
              outlined
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-select
              v-model="flux.flux_type"
              dense
              :items="['entrer', 'sortir']"
              label="Type  Flux"
              outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-btn
              color="primary"
              @click="newFlux"
          >
            Enregistrer
          </v-btn>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'FluxRegister',
  data() {
    return {
      flux: {
        flux_type: '',
        flux_amount: 0,
        flux_create_date: '',
        supply_id: 0,
      },
      supplies: [],
    }
  },
  mounted() {
    this.loadSupply()
  },
  methods: {
    loadSupply() {
      this.$axios.get(this.$endpoint.LoadSupplies).then(rp => {
        this.supplies = rp.data
      })
    },
    newFlux() {
      this.$axios.post(this.$endpoint.CreateFlux, this.flux).then(rp => {
        this.$emit('success-flux')
        console.log(rp)
      })
    },
  },
}
</script>

<style scoped>

</style>
