<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="flux"
        item-key="id"
        class="table-rounded"
        :search="search"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Liste des Flux</v-toolbar-title>
        </v-toolbar>
        <v-spacer></v-spacer>
        <v-row style="margin-left: 1.5rem; margin-right: 1.5rem">
          <!--          class="d-flex"-->
          <v-col
              cols="12"
              sm="4"
          >
            <!--          <v-select-->
            <!--              v-model="filtreTable"-->
            <!--              dense-->
            <!--              :items="[]"-->
            <!--              label="Filtré par status"-->
            <!--              outlined-->
            <!--              :item-value="'code'"-->
            <!--              :item-text="'name'"-->
            <!--          ></v-select>-->
          </v-col>
          <!--          class="d-flex"-->
          <v-col
              cols="12"
              sm="4"
          >
            <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="activeFlux=true"
            >
              Nouveau Flux
            </v-btn>
          </v-col>
          <!--          class="d-flex"-->
          <v-col
              cols="12"
              sm="4"
          >
            <v-text-field
                v-model="search"
                dense
                append-icon="mdi-magnify"
                label="Rechercher un flux"
                outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <template>
      <v-row justify="center">
        <v-dialog
            v-model="activeFlux"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
                dark
                color="primary"
            >
              <v-btn
                  color="primary"
                  @click="activeFlux = false"
              >
                <mdi-close-circle />Annuler
              </v-btn>&emsp;
              <v-toolbar-title color="white">
                Enregistrer un nouveau Flux
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tab-item>
                <v-btn
                    color="primary"
                    @click="activeFlux = false"
                >
                  <mdi-close-circle />Fermer
                </v-btn>&emsp;
              </v-tab-item>
            </v-toolbar>
            <FluxRegister @success-flux="closeFlux"></FluxRegister>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import {mdiDotsVertical, mdiSquareEditOutline} from '@mdi/js'
import FluxRegister from "@/views/supplies/FluxRegister";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Flux',
  components: {FluxRegister},
  setup() {
    return {
      headers: [
        {text: '#', value: 'id'},
        {text: 'TYPE', value: 'flux_type'},
        {text: 'QUANTITE', value: 'flux_amount'},
        {text: 'FOURNITURE', value: 'supply.sup_name'},
        {text: 'DATE', value: 'flux_create_date'},
      ],

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  data() {
    return {
      activeFlux: false,
      flux: [],
      search: '',
    }
  },
  mounted() {
    this.loadFlux()
  },
  methods: {
    loadFlux() {
      this.$axios.get(this.$endpoint.LoadFlux).then(rp => {
        this.flux = rp.data
      })
    },
    closeFlux() {
      this.activeFlux = false
      this.loadFlux()
    },
  }
  ,
}
</script>

<style scoped>

</style>
